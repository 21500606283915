import React, { useEffect, useCallback, useState } from 'react';
import Web3 from 'web3';
import erc20ABI from '../abis/erc20.json';
import faucetABI from '../abis/pron-faucet.json';
import MetaMask from '../assets/images/metamask.svg';

const PronFaucet = () => {
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [faucet, setFaucet] = useState(null);
  const [networkStatus, setNetworkStatus] = useState(null); // 'correct', 'wrong', or null
  const [ethBalance, setEthBalance] = useState('0');
  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);
  const [tokenAmount, setTokenAmount] = useState('0');
  const [cooldownMessage, setCooldownMessage] = useState('');
  const [isRequestAllowed, setIsRequestAllowed] = useState(false);

  const tokenAddress = '0x2b277782Ca8e19d490a470CA43087819b4BaA5F2'; // Token Address for PRON
  const faucetAddress = '0x7d968b1b00a9872e9e22332b190031b8124f69bb'; // Faucet Address
  const targetNetwork = '0x13882'; // Polygon Amoy Testnet chain ID in hexadecimal

  const handleNetworkChange = useCallback(() => {
    if (window.ethereum) {
      window.ethereum.request({ method: 'eth_chainId' }).then((chainId) => {
        if (chainId !== targetNetwork) {
          switchNetwork(); // Automatically switch network
        }
      });
    }
  }, []);

  useEffect(() => {
    handleNetworkChange();

    return () => {
      window.ethereum && window.ethereum.removeListener('chainChanged', handleNetworkChange);
    };
  }, [handleNetworkChange]);

  useEffect(() => {
    if (window.ethereum) {
      setIsMetaMaskInstalled(true);
      const web3 = new Web3(window.ethereum);

      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(async (accounts) => {
          setAccount(accounts[0]);

          // Check for the correct network
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (chainId === targetNetwork) {
            setNetworkStatus('correct');
          } else {
            setNetworkStatus('wrong');
            switchNetwork(); // Prompt to switch network
          }

          const tokenContract = new web3.eth.Contract(erc20ABI, tokenAddress);
          const faucetContract = new web3.eth.Contract(faucetABI, faucetAddress);
          setFaucet(faucetContract);

          // Fetch token balance
          const balance = await tokenContract.methods.balanceOf(accounts[0]).call();
          setBalance(web3.utils.fromWei(balance, 'ether'));

          // Fetch ETH balance
          const ethBal = await web3.eth.getBalance(accounts[0]);
          setEthBalance(web3.utils.fromWei(ethBal, 'ether'));

          // Fetch the token amount from the faucet contract
          const tokenAmount = await faucetContract.methods.tokenAmount().call();
          setTokenAmount(web3.utils.fromWei(tokenAmount, 'ether'));
          

          // Check cooldown
          const allowedToWithdraw = await faucetContract.methods.allowedToWithdraw(accounts[0]).call();
          setIsRequestAllowed(allowedToWithdraw);

          if (!allowedToWithdraw) {
            const lastAccessTime = await faucetContract.methods.lastAccessTime(accounts[0]).call();
            const waitTime = await faucetContract.methods.waitTime().call();
            const timeLeft = Number(lastAccessTime) + Number(waitTime) - Math.floor(Date.now() / 1000);

            if (timeLeft > 0) {
              setCooldownMessage(`You can request tokens in ${(timeLeft / 60).toFixed(0)} minutes.`);
            }
          }
        })
        .catch((err) => {
          console.error('User rejected MetaMask connection:', err);
        });
    } else {
      // MetaMask is not installed
      setIsMetaMaskInstalled(false);
    }

    window.ethereum && window.ethereum.on('chainChanged', handleNetworkChange);

    return () => {
      window.ethereum && window.ethereum.removeListener('chainChanged', handleNetworkChange);
    };
  }, [handleNetworkChange]);

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetNetwork }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: targetNetwork,
                chainName: 'Polygon Amoy Testnet',
                rpcUrls: ['https://rpc-amoy.polygon.technology'],
                nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
                blockExplorerUrls: ['https://amoy.polygonscan.com'],
              },
            ],
          });
        } catch (addError) {
          console.error('Error adding Polygon Amoy Testnet:', addError);
        }
      } else {
        console.error('Error switching network:', switchError);
      }
    }
  };

  const addToMetaMask = async () => {
    try {
      const tokenDetails = {
        address: tokenAddress,
        symbol: 'PRON',
        decimals: 18,
        image: 'https://tokens.waveswaps.com/images/tokens/PRON.svg',
      };

      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: tokenDetails,
          },
        });
        alert('Token added to MetaMask!');
      }
    } catch (error) {
      console.error('Error adding token to MetaMask', error);
    }
  };

  const requestTokens = async () => {
    if (faucet && isRequestAllowed) {
      try {
        const gasPrice = Web3.utils.toWei('50', 'gwei'); // Aggressive gas price (50 gwei or higher)
  
        // Estimate gas limit for the transaction
        const gasLimit = await faucet.methods.requestTokens().estimateGas({ from: account });
  
        await faucet.methods.requestTokens().send({
          from: account,
          gasPrice: gasPrice, // Set aggressive gas price
          gas: gasLimit, // Use the estimated gas limit for efficiency
        });
  
        // Refresh token balance after successful request
        const web3 = new Web3(window.ethereum);
        const tokenContract = new web3.eth.Contract(erc20ABI, tokenAddress);
        const updatedBalance = await tokenContract.methods.balanceOf(account).call();
        setBalance(web3.utils.fromWei(updatedBalance, 'ether'));
  
        alert('Tokens requested successfully');
      } catch (error) {
        console.error('Error requesting tokens: ', error);
      }
    } else {
      alert('You cannot request tokens yet. ' + cooldownMessage);
    }
  };
  

  const handleInstallMetaMask = () => {
    if (!isMetaMaskInstalled) {
      window.open('https://metamask.io/download.html', '_blank');
    }
  };

  return (
    <div className="faucet-container mt-3">
              <div className="button-container">
          <a
            className="faucet-button mt-1 mb-3"
            style={{ width: '200px' }}
            href="/"
          >
            <span className="icon" role="img" aria-label="back to token select">🔙</span>
            <span className="button-text"> to Token Select</span>
          </a>
          <a
            className="pfaucet-button mt-1 mb-3"
            style={{ width: '200px' }}
            href="https://faucet.polygon.technology/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon" role="img" aria-label="external link">🚰</span>
            <span className="button-text"> Polygon Faucet</span>
          </a>
        </div>

      <h2 className="h2-faucet mt-3">PronCoin (PRON) Faucet</h2>

      {!isMetaMaskInstalled && (
        <div className="alert alert-danger mx-auto" style={{ width: '460px' }}>
          <strong>MetaMask is not installed!</strong> Please install MetaMask to proceed.
          <button className="btn btn-primary mt-2" onClick={handleInstallMetaMask}>Install MetaMask</button>
        </div>
      )}

      {isMetaMaskInstalled && (
        <>
          <div className="centered-container">
            <div id="description" className="text-center mx-auto mt-3">
            <button
              className="mm-button h5-faucet"
              onClick={addToMetaMask}
              style={{ cursor: 'pointer' }}
            >
              <span className="icon" role="img" aria-label="add to MetaMask">
                <img src={MetaMask} alt="Add to MetaMask" className="metamask" /></span>
              <span className="button-text"> add PRON to MetaMask</span>
            </button>
          </div>
          </div>
          <div id="description" className="text-center mx-auto mt-3">
              <p id="intro">
                This faucet provides a seamless way to receive test tokens through a simple, smart contract-based process. Just connect your MetaMask wallet and click the button below to get started.
                </p>
              </div>

          {/* Network Status */}
          {networkStatus === 'wrong' && (
            <div className="alert alert-danger mx-auto" style={{ width: '100%', maxWidth: '460px' }}>
              <strong>You are not connected to the correct network.</strong> Please switch to it via MetaMask.
            </div>
          )}

          {networkStatus === 'correct' && (
            <div className="alert alert-success mx-auto" style={{ width: '460px' }}>
              <strong>You are connected to the correct testnet - great!<br /></strong> You can now request tokens.
            </div>
          )}

          {/* Faucet Section */}
          {networkStatus === 'correct' && (
            <>
              <div id="faucet" className="centered-container">
                <p>Your address:<br /><span id="address" className="fetched-content"><strong>{account}</strong></span></p>
                <p>Native token balance:<br /><span id="balanceETH"><strong>{parseFloat(ethBalance).toFixed(2)} MATIC</strong></span></p>
                <p>Token balance:<br /><span id="balanceToken"><strong>{parseFloat(balance).toFixed(2)} PRON</strong></span></p>

                {/* Update the button to display token amount */}
                <button id="requestButton" className="request-button mb-3 mt-3" onClick={requestTokens} disabled={!isRequestAllowed}>
                  Request {parseFloat(tokenAmount).toFixed(0)} Test Tokens
                </button>

                {cooldownMessage && (
                  <div id="warning" className="alert alert-warning mx-auto mt-3" style={{ width: '460px' }}>
                    {cooldownMessage}
                  </div>
                )}
              </div>

              <div id="description" className="text-center centered-container mx-auto mt-3">
                <p>Network name:<br /><strong>POLYGON AMOY TESTNET</strong></p>
                <p>New RPC URL:<br /><strong>https://rpc-amoy.polygon.technology</strong></p>
                <p>Chain ID:<br /><strong>80002</strong></p>
                <p>Currency symbol:<br /><strong>MATIC</strong></p>
                <p>Block explorer URL:<br /><a href="https://amoy.polygonscan.com/" target="_blank" rel="noopener noreferrer">https://amoy.polygonscan.com/</a></p>
              </div>
              <div id="description" className="text-center mx-auto mt-3">
              <p id="intro">
              This is WaveSwaps' gateway to acquiring test tokens for testing the ecosystem's operations. As we develop groundbreaking blockchain solutions and integrate innovative features unique to WaveSwaps, testing our smart contracts is crucial.<br/>The Token Faucet app is optimized for desktop users but is fully operable for mobile users as well. Depending on your device and operating system (iOS, Android, etc.), you may need to manually add the Polygon Amoy Testnet settings to your wallet for optimal functionality.
                </p>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PronFaucet;
