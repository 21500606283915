import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import FlipNotification from './components/FlipNotification'; // Import the FlipNotification component
import Home from './components/Home'; 
import Landing from './components/Landing'; 
import CodeFaucet from './components/CodeFaucet';
import GetonFaucet from './components/GetonFaucet';
import GroshFaucet from './components/GroshFaucet';
import MetaFaucet from './components/MetaFaucet';
import PiplFaucet from './components/PiplFaucet';
import PronFaucet from './components/PronFaucet';
import WaveFaucet from './components/WaveFaucet';
import GblFaucet from './components/GblFaucet';
import NotFound from './components/NotFound'; 
import './App.css'; 

function App() {
  return (
    <Router>
      <div className="App">
        <FlipNotification /> {/* Add the notification above the header */}
        <Header />
        {/* Main content area */}
        <div className="content">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/home" element={<Home />} /> {/* Assuming there's a Home route */}
            <Route path="/code" element={<CodeFaucet />} />
            <Route path="/geton" element={<GetonFaucet />} />
            <Route path="/grosh" element={<GroshFaucet />} />
            <Route path="/meta" element={<MetaFaucet />} />
            <Route path="/pipl" element={<PiplFaucet />} />
            <Route path="/pron" element={<PronFaucet />} />
            <Route path="/wave" element={<WaveFaucet />} />
            <Route path="/gbl" element={<GblFaucet />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {/* Footer is outside the Routes, so it will always be rendered at the bottom */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
