// src/components/FlipNotification.js
import React, { useState, useEffect } from 'react';
import { Flipper, Flipped } from 'react-flip-toolkit';

const notifications = [
    "🌊 Welcome to WaveSwaps 🌊",
    "📢 Join us on Telegram 📢",
    "💡 Discover new features 💡",
    "📈 Stay tuned 📈",
];

const FlipNotification = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % notifications.length);
        }, 5000); // Change notification every 5 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <Flipper flipKey={index}>
            <Flipped flipId="notification">
                <div className="notification">
                    {notifications[index]}
                </div>
            </Flipped>
        </Flipper>
    );
};

export default FlipNotification;
