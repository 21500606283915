import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import Wave from '../assets/tokens/wave.svg';
import Gbl from '../assets/tokens/gbl.svg';
import Pipl from '../assets/tokens/pipl.svg';
import Pron from '../assets/tokens/pron.svg';
import Grosh from '../assets/tokens/grosh.svg';
import Meta from '../assets/tokens/meta.svg';
import Code from '../assets/tokens/code.svg';
import Geton from '../assets/tokens/geton.svg';


const Landing = () => {
  return (
    <main> {/* Ensure main takes up the remaining space */}
      <section className="card-section py-5">
        <h2 className="text-center h2-faucet py-5 mb-2">Select Your Token Faucet</h2>
        {/* Card layout for larger screens */}
        <div className="card-layout">
        <div className="token-card">
        <img src={Wave} alt="WaveCoin" className="token" />
            <h3 className="text-center">WAVE</h3>
            <Link to="/wave" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Gbl} lt="WaveCoin" className="token" />
            <h3 className="text-center">GBL</h3>
            <Link to="/gbl" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Meta} alt="MetaCoin" className="token" />
            <h3 className="text-center">META</h3>
            <Link to="/meta" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Code} alt="CryptoCode" className="token" />
            <h3 className="text-center">CODE</h3>
            <Link to="/code" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Geton} alt="GetonCoin" className="token" />
            <h3 className="text-center">GETON</h3>
            <Link to="/geton" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Grosh} alt="GroshCoin" className="token" />
            <h3 className="text-center">GROSH</h3>
            <Link to="/grosh" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Pipl} alt="PiplCoin" className="token" />
            <h3 className="text-center">PIPL</h3>
            <Link to="/pipl" className="faucet-button">Open Faucet</Link>
          </div>
          <div className="token-card">
          <img src={Pron} alt="PronCoin" className="token" />
            <h3 className="text-center">PRON</h3>
            <Link to="/pron" className="faucet-button">Open Faucet</Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Landing;
