import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import Logo from '../assets/images/logo.svg';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const checkScreenSize = () => {
    if (window.innerWidth <= 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = (menuName) => {
    if (activeDropdown === menuName) {
      setActiveDropdown(null); // Close the dropdown if already open
    } else {
      setActiveDropdown(menuName); // Open the clicked dropdown
    }
  };

  const handleMouseEnter = (menuName) => {
    if (!isSmallScreen) {
      setActiveDropdown(menuName); // Open dropdown on hover (large screens)
    }
  };

  const handleMouseLeave = () => {
    if (!isSmallScreen) {
      setActiveDropdown(null); // Close dropdown on mouse leave (large screens)
    }
  };

  return (
    <header className="header">
      <div className="container">
        <div className="logo mb-2 mt-1">
          <a href="https://test.waveswaps.com" rel="noopener noreferrer">
            <img src={Logo} alt="WaveSwaps" className="logo" />
          </a>
        </div>

        <div className="hamburger" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <nav className={`navigation ${isMenuOpen ? 'open' : ''}`}>
          <ul className="mb-1">
            <li><Link to="/">Home</Link></li>

            <li
              className={`dropdown ${activeDropdown === 'staking' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('staking')}
              onMouseLeave={handleMouseLeave}
              onClick={() => toggleDropdown('staking')}
            >
              <span className="dropdown-toggle">
                Staking
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'staking' ? 'show' : ''}`}>
                <li>
                  <a href="https://test.waveswaps.com/staking" rel="noopener noreferrer">Stake WAVE</a>
                </li>
              </ul>
            </li>

            <li
              className={`dropdown ${activeDropdown === 'nodes' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('nodes')}
              onMouseLeave={handleMouseLeave}
              onClick={() => toggleDropdown('nodes')}
            >
              <span className="dropdown-toggle">
                Nodes
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'nodes' ? 'show' : ''}`}>
                <li>
                  <a href="https://test.waveswaps.com/store" rel="noopener noreferrer">Market</a>
                </li>
                <li>
                  <a href="https://test.waveswaps.com/craft" rel="noopener noreferrer">Crafting</a>
                </li>
                <li>
                  <a href="https://test.waveswaps.com/node-info" rel="noopener noreferrer">About</a>
                </li>
              </ul>
            </li>


            <li
              className={`dropdown ${activeDropdown === 'demo' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('demo')}
              onMouseLeave={handleMouseLeave}
              onClick={() => toggleDropdown('demo')}
            >
              <span className="dropdown-toggle">
                Demo
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'demo' ? 'show' : ''}`}>
                <li>
                  <a href="https://test.waveswaps.com" rel="noopener noreferrer">Liquidity Nodes</a>
                </li>
                <li>
                  <a href="https://faucet.waveswaps.com/" rel="noopener noreferrer">Token Faucet</a>
                </li>
              </ul>
            </li>

            <li
              className={`dropdown ${activeDropdown === 'info' ? 'open' : ''}`}
              onMouseEnter={() => handleMouseEnter('info')}
              onMouseLeave={handleMouseLeave}
              onClick={() => toggleDropdown('info')}
            >
              <span className="dropdown-toggle">
                Info
              </span>
              <ul className={`dropdown-menu ${activeDropdown === 'info' ? 'show' : ''}`}>
                <li>
                  <a href="https://test.waveswaps.com/tokens" rel="noopener noreferrer">Tokens</a>
                </li>
                <li>
                  <a href="https://test.waveswaps.com/node-info" rel="noopener noreferrer">About Nodes</a>
                </li>
                <li>
                  <a href="https://test.waveswaps.com/FAQs" rel="noopener noreferrer">FAQs</a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
